<template>
  <div>
    <div class="ticketTextHeading">Note</div>
    <v-row>
      <v-col cols="6" md="3">
        <v-text-field
          hide-details=""
          outlined
          :disabled="loading"
          height="43px"
          dense
          v-model="search"
          label="Search by  Note, Note Category, Subject"
          append-icon="mdi-magnify"
          type="text"
          color="#38227A"
          class="search-input"
          style="margin: 5px"
        >
        </v-text-field>
      </v-col>

      <v-col cols="6" md="3">
        <v-btn
          color="#38227A"
          dark
          class="px-11 text-capitalize"
          style="border-radius: 10px; margin: 5px; width: 130px"
          height="45px"
          @click="toggleNoteModal({ show: true, type: 'add' })"
        >
          <span>Add Note</span>
        </v-btn>
      </v-col>
    </v-row>
    <br />
    <v-data-table
      fixed-header
      hide-default-footer
      :headers="header"
      :items="items"
      :loading="loading"
      class="elevation-8"
      :options.sync="dataOptions"
      :items-per-page="page_info.detail_count"
      :server-items-length="page_info.detail_count"
      loading-text="Loading... Please wait"
      mobile-breakpoint="0"
    >
      <template v-slot:item="props">
        <tr>
          <td class="text-center">
            <div style="font-size: 12px; color: #757575">
              {{ props.item.subject }}
            </div>
          </td>
          <td class="text-center">
            <div style="font-size: 12px; color: #757575">
              <div v-html="props.item.note.substring(0, 20)">...</div>
            </div>
          </td>
          <td class="text-center">
            <div style="font-size: 12px; color: #757575">
              {{ props.item.note_category }}
            </div>
          </td>
          <td class="text-center">
            <div style="font-size: 12px; color: #757575">
              {{ props.item.created_by }}
            </div>
          </td>
          <td class="text-center">
            <div style="font-size: 12px; color: #757575">
              {{ props.item.modified_on }}
            </div>
          </td>
          <td class="text-center">
            <div style="font-size: 12px; color: #757575">
              {{ props.item.modified_at }}
            </div>
          </td>
          <td class="text-center">
            <div style="font-size: 12px; color: #757575">
              <v-chip
                class="actionColumn-btn"
                color="#F2F2F2"
                @click="
                  toggleNoteModal({
                    show: true,
                    type: 'edit',
                    id: props.item.id,
                  })
                "
              >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      dark
                      v-bind="attrs"
                      v-on="on"
                      class="icons"
                      color="#6B6B6B"
                      size="22"
                    >
                      mdi-pencil
                    </v-icon>
                  </template>
                  <span>Edit</span>
                </v-tooltip>
              </v-chip>
              <v-chip
                class="actionColumn-btn"
                color="#F2F2F2"
                @click="
                  toggleDeleteNoteModal({ Delete: true, id: props.item.id })
                "
                v-if="
                  (props.item.user_type == 'admin' ||
                    props.item.user_type == 'Sales Representative' ||
                    props.item.user_type == 'Event Support Staff') &&
                  props.item.note_category != 'Snapshot- Sales Summary'
                "
              >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      dark
                      v-bind="attrs"
                      v-on="on"
                      class="icons"
                      color="#6B6B6B"
                      size="22"
                    >
                      mdi-delete
                    </v-icon>
                  </template>
                  <span>Delete</span>
                </v-tooltip>
              </v-chip>
              <v-chip
                class="actionColumn-btn"
                color="#F2F2F2"
                @click="
                  toggleNoteModal({
                    show: true,
                    type: 'view',
                    id: props.item.id,
                  })
                "
              >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      dark
                      v-bind="attrs"
                      v-on="on"
                      class="icons"
                      color="#6B6B6B"
                      size="22"
                    >
                      mdi-eye
                    </v-icon>
                  </template>
                  <span>View</span>
                </v-tooltip>
              </v-chip>
            </div>
          </td>
        </tr>
      </template>
      <template v-slot:footer>
        <div>
          <v-divider></v-divider>
          <v-row no-gutters class="pt-6 px-2">
            <v-col
              class="mb-4"
              align-self="center"
              cols="12"
              xs="12"
              sm="6"
              md="4"
            >
              <div
                class="justify-center justify-md-start d-flex px-5 tableHeader-text"
              >
                <div class="alignSelf-center mr-3">Show</div>
                <div class="alignSelf-center mr-5 pb-2" style="width: 60px">
                  <v-select
                    class=""
                    :items="itemsPerPageList"
                    hide-details
                    v-model="page_info.detail_count"
                    dense
                  ></v-select>
                </div>

                <div class="alignSelf-center">
                  {{ page_info.page_number }}
                  of
                  {{ page_info.total_page }}
                </div>
              </div>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="12" sm="4" md="4">
              <div>
                <v-pagination
                  :length="page_info.total_page"
                  v-model="page_info.page_number"
                  :total-visible="5"
                  color="#D30024"
                  class="pagination"
                >
                </v-pagination>
              </div>
            </v-col>
          </v-row>
        </div>
      </template>
    </v-data-table>
    <delete-note-modal @reload="deleteData()"></delete-note-modal>
    <note-modal @reload="getNoteData()"></note-modal>
  </div>
</template>
<script>
import Axios from "@/api/BaseAxios";
import {
  NOTE_SECTION_GET_LIST_API,
  NOTE_SECTION_DELETE_API,
} from "@/constants/APIUrls";
import { mapActions, mapGetters } from "vuex";
import {
  SORTING_KEY,
  SEARCH,
  PAGE_INFO,
  ITEMS_PER_PAGE,
  PAGE_NUMBER,
  TOTAL_PAGE,
} from "@/constants/APIKeys";
import _ from "lodash";
export default {
  name: "GameDashboardVASHN",
  components: {
    DeleteNoteModal: () => import("../DeleteNoteModal/DeleteNoteModal"),
    NoteModal: () => import("./NoteModal/NoteModal"),
  },
  data() {
    return {
      dataOptions: { sortBy: [], sortDesc: [] },
      adminAccess: localStorage.getItem("adminAccess"),
      loading: false,
      header: [
        {
          sortable: true,
          text: "Subject",
          value: "subject",
          align: "center",
          class: ["tableHeader-text", "tableHeader-bg"],
        },
        {
          sortable: false,
          text: "Note",
          align: "center",
          class: ["tableHeader-text", "tableHeader-bg"],
        },
        {
          sortable: true,
          text: "Note Category",
          value: "note_category",
          align: "center",
          class: ["tableHeader-text", "tableHeader-bg"],
        },
        {
          sortable: false,
          text: "Note By",
          align: "center",
          class: ["tableHeader-text", "tableHeader-bg"],
        },
        {
          sortable: true,
          text: "Date",
          value: "created",
          align: "center",
          class: ["tableHeader-text", "tableHeader-bg"],
        },
        {
          sortable: false,
          text: "Time",
          align: "center",
          class: ["tableHeader-text", "tableHeader-bg"],
        },
        {
          sortable: false,
          text: "Action",
          align: "center",
          class: ["tableHeader-text", "tableHeader-bg"],
        },
      ],
      items: [],
      [SORTING_KEY]: "",
      [SEARCH]: "",
      [PAGE_INFO]: {
        [PAGE_NUMBER]: 1,
        [TOTAL_PAGE]: 5,
        [ITEMS_PER_PAGE]: 10,
      },
      itemsPerPageList: [10, 15, 20, 30, 40, 50, 100],
    };
  },
  computed: {
    ...mapGetters({
      selectGameData: "gameManagement/getGame",
    }),
  },
  watch: {
    "dataOptions.sortBy": function () {
      if (this.dataOptions.sortBy.length !== 0) {
        this.sorting_key = this.dataOptions.sortDesc[0]
          ? `-${this.dataOptions.sortBy[0]}`
          : this.dataOptions.sortBy[0];
        this.getNoteData();
      } else {
        this.sorting_key = "";
        this.getNoteData();
      }
    },
    search() {
      this.debounceGetNoteData();
    },
    "page_info.page_number": function () {
      this.getNoteData();
    },
    "page_info.detail_count": function () {
      this.getNoteData();
    },
  },
  created() {
    this.$root.$refs.gameDashboardN = this;
  },
  methods: {
    ...mapActions({
      toggleDeleteNoteModal: "gameManagement/toggleDeleteNoteModal",
      showToast: "snackBar/showToast",
      toggleNoteModal: "gameManagement/toggleNoteModal",
    }),
    openModal() {
      this.toggleNoteModal({ show: true, type: "add" });
    },

    deleteData() {
      this.deleteNoteData();
    },
    getNoteData() {
      this.loading = true;
      const successHandler = (res) => {
        this.items = res.data.note_list;
        this.page_info[PAGE_NUMBER] = res.data[PAGE_INFO][PAGE_NUMBER];
        this.page_info[TOTAL_PAGE] = res.data[PAGE_INFO][TOTAL_PAGE];
        this.page_info[ITEMS_PER_PAGE] = res.data[PAGE_INFO][ITEMS_PER_PAGE];
        this.loading = false;
      };
      const failureHandler = (res) => {
        console.log(res);
        this.loading = false;
      };
      let formData = {};
      formData["game_id"] = this.$route.query.game_id;
      formData[SORTING_KEY] = this[SORTING_KEY];
      formData[SEARCH] = this.search;
      formData[PAGE_NUMBER] = this.page_info[PAGE_NUMBER];
      formData[ITEMS_PER_PAGE] = this.page_info.detail_count;
    Axios.request_GET(
      NOTE_SECTION_GET_LIST_API,
      formData,
      {},
      successHandler,
      failureHandler,
      false
    );
  },
  deleteNoteData() {
    const successHandler = (res) => {
      console.log(res);
      this.showToast({
        message: "Deleted successfully.",
        color: "s",
      });
    };
    const failureHandler = (res) => {
      console.log(res);
      this.showToast({
        message: res,
        color: "e",
      });
    };
    const finallyHandler = () => {
      this.getNoteData();
    };
    let formData = {};
    formData["id"] = this.$store.state.gameManagement.deleteNoteModal.noteID;
    Axios.request_DELETE(
      NOTE_SECTION_DELETE_API,
      formData,
      {},
      successHandler,
      failureHandler,
      false,
      true,
      finallyHandler
    );
  },

    pageReload() {
      location.reload();
    },
  },
  mounted() {
    // this.getNoteData();
    this.debounceGetNoteData = _.debounce(this.getNoteData, 500);
  },
};
</script>
<style scoped>
.ticketTextHeading {
  font-family: Roboto Slab;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 140%;
  /* identical to box height, or 45px */

  color: #2c1963;
}
span.v-chip {
  border-radius: 4px;
  margin-left: 12px;
}
.v-data-table.elevation-8.v-data-table--fixed-header.v-data-table--has-top.theme--light {
  border-radius: 6px;
}
.tableText {
  font-family: Lato;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 145%;
  /* identical to box height, or 25px */

  color: #1d1d1d;
}
</style>
<style>
.tableHeader-text {
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 17px !important;
  line-height: 161.5% !important;
  color: #1d1d1d !important;
  padding: 0px 4px !important;
}
.tableHeader-bg {
  background: #e7e5ed !important;
}
.alignSelf-center {
  align-self: center;
}
span.actionColumn-btn {
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.12);
  height: 45px;
  width: 38px;
  cursor: pointer;
}
.actionColumn-btn {
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.12);
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 960px and up) */
@media only screen and (min-width: 960px) {
  .tableHeader-text {
    font-size: 13px !important;
  }
}

/* Large devices (laptops/desktops, 1264px and up) */
@media only screen and (min-width: 1264px) {
  .tableHeader-text {
    font-size: 14px !important;
  }
}

/* Extra large devices (large laptops and desktops, 1904px and up) */
@media only screen and (min-width: 1904px) {
  .tableHeader-text {
    font-size: 16px !important;
  }
}
</style>
